import React, { useCallback, useState } from 'react'
import classNames from 'classnames'

import styles from './Input.module.scss'

interface IInputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: any
    containerClassName?: string
    labelClassName?: string
    inputClassName?: string
    label?: string
    labelHint?: string | JSX.Element
    placeholder?: string
    prefix?: string
    value?: string
    type?: 'text' | 'password' | 'email'
    error?: boolean
    disabled?: boolean
    hint?: string
}
const Input = (props: IInputProps) => {
    const {
        onChange,
        onBlur,
        containerClassName,
        labelClassName,
        inputClassName,
        label,
        labelHint,
        placeholder = '',
        prefix = '',
        value = '',
        type = 'text',
        error = false,
        disabled = false,
        hint = '',
    } = props
    const [currentType, setCurrentType] = useState<string>(type)

    const onChangeVisible = useCallback(() => {
        if (currentType === 'password') setCurrentType('text')
        if (currentType === 'text') setCurrentType('password')
    }, [currentType])

    return (
        <div className={classNames(styles.root, containerClassName)}>
            <div className={styles.labelContainer}>
                <label className={classNames(styles.label, labelClassName)}>{label}</label>
                {labelHint && labelHint}
            </div>
            <div className={styles.inputWrap}>
                <input
                    className={classNames(styles.input, inputClassName, {
                        [styles.error]: error,
                        [styles.password]: type === 'password',
                    })}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    value={value && value.includes(prefix) ? value : prefix + value}
                    type={currentType}
                    disabled={disabled}
                />
                {type === 'password' && (
                    <div className={styles.suffix} onClick={onChangeVisible}>
                    </div>
                )}
            </div>
            {hint && <p className={styles.hint}>{hint}</p>}
        </div>
    )
}

export default Input
