import React from 'react';
import {Route, Routes} from "react-router-dom";

import './styles/globals.scss'
import './components/Header/Header.scss';
import './components/Footer/Footer.module.scss';
import './components/Search/Search.scss';
import './components/Card/Card.scss';

import HomePage from "./pages/HomePage/HomePage";
import AccountPage from "./pages/AccountPage/AccountPage";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path='*' element={<HomePage />} />
        </Routes>
    </div>
  );
}

export default App;
