import { call, put } from 'redux-saga/effects'

import { authenticationActions } from './actions'
import {IInputAuthForm} from "../models/IInputAuthForm";
import authenticationApi from "../api/authentificationApi";


type actionType = {
    type: string
    payload: IInputAuthForm
}

export function* workerLogin({ payload }: actionType): any {
    yield put(authenticationActions.loginRequest())

    const response = yield call(authenticationApi.login, payload)
    if (response) {
        localStorage.setItem('@ACCESS_TOKEN', response.data.token)
        yield put(authenticationActions.loginSuccess(true))
    } else {
        yield put(authenticationActions.loginFailed(null))
    }
}

export function* workerRegistration({ payload }: actionType): any {
    yield put(authenticationActions.registrationRequest())

    const response = yield call(authenticationApi.registration, payload)

    if (response) {
        localStorage.setItem('@ACCESS_TOKEN', response.data.token)
        yield put(authenticationActions.registrationSuccess(true))
    } else {
        yield put(authenticationActions.registrationFailed(null))
    }
}

export function* workerLogout(): any {
    yield put(authenticationActions.logoutRequest())

    const response = yield call(authenticationApi.logout)

    if (response) {
        localStorage.removeItem('@ACCESS_TOKEN')
        yield put(authenticationActions.logoutSuccess())
    } else {
        yield put(authenticationActions.logoutFailed(null))
    }
}
