export const authenticationTypes = {
    LOGIN_REQUEST: 'AUTHENTICATION|LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTHENTICATION|LOGIN_SUCCESS',
    LOGIN_FAILED: 'AUTHENTICATION|LOGIN_FAILED',
    LOGIN_ASYNC: 'AUTHENTICATION|LOGIN_ASYNC',

    REGISTRATION_REQUEST: 'AUTHENTICATION|REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS: 'AUTHENTICATION|REGISTRATION_SUCCESS',
    REGISTRATION_FAILED: 'AUTHENTICATION|REGISTRATION_FAILED',
    REGISTRATION_ASYNC: 'AUTHENTICATION|REGISTRATION_ASYNC',

    LOGOUT_REQUEST: 'AUTHENTICATION|LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTHENTICATION|LOGOUT_SUCCESS',
    LOGOUT_FAILED: 'AUTHENTICATION|LOGOUT_FAILED',
    LOGOUT_ASYNC: 'AUTHENTICATION|LOGOUT_ASYNC',

    REFRESH_REQUEST: 'AUTHENTICATION|REFRESH_REQUEST',
    REFRESH_SUCCESS: 'AUTHENTICATION|REFRESH_SUCCESS',
    REFRESH_FAILED: 'AUTHENTICATION|REFRESH_FAILED',
    REFRESH_ASYNC: 'AUTHENTICATION|REFRESH_ASYNC',
}
