import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { rootSaga } from '../saga/rootSaga'
import {rootReducer} from "./rootReducer";

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: rootReducer(),
    middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

export default store

export type RootState = ReturnType<typeof store.getState>
