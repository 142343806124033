import baseApi from './baseApi'
import {IInputAuthForm} from "../models/IInputAuthForm";

const authenticationApi = {

    login: (data: IInputAuthForm) => {
        return baseApi.post('/login', data)
    },

    registration: (data: IInputAuthForm) => {
        return baseApi.post('/registration', data)
    },

    refresh: () => {
        return baseApi.get('/refresh')
    },

    logout: () => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({
                    token: true,
                })
            }, 300)
        })
    },
}

export default authenticationApi
