import { call, put } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import {FiltersCountriesResponse, FiltersInfo, FiltersModels} from "../../models/filterTypes";
import {shopApi} from "../../api/shopApi";
import {filterActions} from "./filterActions";

export function* getFiltersWorker() {
    const response: AxiosResponse<FiltersCountriesResponse> = yield call(shopApi.getFilters)
    const brands: FiltersModels[] = []
    const models: FiltersModels[] = []
    const countries: FiltersModels[] = []
    if (response) {
        response.data.countries.forEach((c) => {
            countries.push({id: c.id, title: c.title})
            c.brands.forEach((b) => {
                brands.push({id: b.id, title: b.title})
                b.models.forEach((m) => models.push({id: m.id, title: m.title}))
            })
        })

        let result: FiltersInfo = {
            brands: brands,
            countries: countries,
            models: models,
            minPrice: response.data.minPrice,
            maxPrice: response.data.maxPrice,
        } ?? {}
        yield put(filterActions.getFiltersSuccess(result))
    } else {
        yield put(filterActions.getFiltersFailure('Нет данных'))
    }
}