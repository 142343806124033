import { all, takeLatest, call } from 'redux-saga/effects'

import { authenticationTypes } from './types'
import {
    workerLogin,
    workerLogout,
    workerRegistration,
} from './workers'

function* watchLogin() {
    yield takeLatest(authenticationTypes.LOGIN_ASYNC, workerLogin)
}

function* watchRegistration() {
    yield takeLatest(authenticationTypes.REGISTRATION_ASYNC, workerRegistration)
}

function* watchLogout() {
    yield takeLatest(authenticationTypes.LOGOUT_ASYNC, workerLogout)
}

export function* watchAuthentication() {
    yield all([
        call(watchLogin),
        call(watchRegistration),
        call(watchLogout),
    ])
}
