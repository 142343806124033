import React, {useEffect, useState} from "react";
import styles from "./Filter.module.scss";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Slider,
    Tab,
    Tabs
} from "@mui/material";
import FilterWrapper from "../FilterWrapper/FilterWrapper";
import {FiltersModels} from "../../models/filterTypes";
import {useDispatch, useSelector} from "react-redux";
import {filterActions} from "./filterActions";
import {getFilters} from "./filterSelector";

const Filter: React.FC = () => {
    const filters = useSelector(getFilters);
    const [tabs, setTabs] = useState('one');
    const [open, setOpen] = useState(true);
    const [inventoryFilter, setInventoryFilter] = useState(true);
    const [priceFilter, setPriceFilter] = useState(true);
    const [brandFilter, setBrandFilter] = useState(true);
    const [modelFilter, setModelFilter] = useState(true);
    const [saleFilter, setSaleFilter] = useState(true);
    const [countryFilter, setCountryFilter] = useState(true);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(filterActions.getRequest())
    }, [])

    const handleClick = () => {
        setOpen(!open);
    };
    const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
        setTabs(newValue);
    };

    const [price, setPrice] = useState<number[]>([filters.minPrice + 100000, filters.maxPrice - filters.minPrice]);

    const handleChangePrice = (event: Event, newValue: number | number[]) => {
        setPrice(newValue as number[]);
    };

    return (
        <form className={styles.filters} action="">
            <Tabs
                value={tabs}
                onChange={handleChangeTabs}
                sx={{
                    "&.MuiTabs-root": {
                        marginBottom: '30px',
                    },
                    "& .MuiTabs-flexContainer": {
                        gap: '40px',
                    },
                    "& button": {
                        padding: '8px 0',
                        fontFamily: 'SF Pro, sans-serif',
                        fontSize: '16px',
                        lineHeight: '19px',
                        letterSpacing: '0.09em',
                        textTransform: 'uppercase',
                        color: '#C4C4C4'
                    },
                    "& button.Mui-selected": {
                        fontWeight: '700',
                        color: '#2F3035'
                    },
                }}>
                <Tab
                    value="one"
                    label="Параметры"
                    wrapped
                />
                <Tab
                    value="two"
                    label="По марке"
                />
            </Tabs>

            <div className={styles.filters__wrapper}>
                <div className={styles.filters__block}>
                    <FilterWrapper onClick={handleClick} filterName={"Наличие"} isOpen={inventoryFilter}>
                        <FormGroup>
                            <FormControlLabel
                                sx={{
                                    "& .MuiTypography-root": {
                                        fontFamily: 'SF Pro',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        lineHeight: '17px',
                                    },
                                }}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>}
                                label="В наличии" />
                            <FormControlLabel
                                sx={{
                                    "& .MuiTypography-root": {
                                        fontFamily: 'SF Pro',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        lineHeight: '17px',
                                    },
                                }}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>}
                                label="Под заказ" />
                        </FormGroup>
                    </FilterWrapper>
                </div>
                <div className={styles.filters__block}>
                    <FilterWrapper onClick={handleClick} filterName={"Цена"} isOpen={priceFilter}>
                        <Slider
                            min={filters.minPrice}
                            max={filters.maxPrice}
                            value={price}
                            onChange={handleChangePrice}
                            valueLabelDisplay="auto"
                        />
                    </FilterWrapper>
                </div>
                <div className={styles.filters__block}>
                    <FilterWrapper onClick={handleClick} filterName={"Бренд"} isOpen={brandFilter}>
                        <FormGroup>
                            {filters.brands.map((brand: FiltersModels, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontFamily: 'SF Pro',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                                lineHeight: '17px',
                                            },
                                        }}
                                        control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>}
                                        label={brand.title}
                                    />
                                )
                            })}
                        </FormGroup>
                    </FilterWrapper>
                </div>
                <div className={styles.filters__block}>
                    <FilterWrapper onClick={handleClick} filterName={"Модель"} isOpen={modelFilter}>
                        <FormGroup>
                            {filters.models.map((model: FiltersModels, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>}
                                        label={model.title}
                                    />
                                )
                            })}
                        </FormGroup>
                    </FilterWrapper>
                </div>
                <div className={styles.filters__block}>
                    <FilterWrapper onClick={handleClick} filterName={"Акции"} isOpen={saleFilter}>
                        <FormGroup>
                            <FormControlLabel
                                sx={{
                                    '& .MuiCheckbox-root': { display: 'none'}
                                }}
                                className={styles.filters__sale}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>}
                                label="SALE" />
                            <FormControlLabel
                                sx={{
                                    '& .MuiCheckbox-root': { display: 'none'}
                                }}
                                className={styles.filters__sale}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>}
                                label="NEW" />
                            <FormControlLabel
                                sx={{
                                    '& .MuiCheckbox-root': { display: 'none'}
                                }}
                                className={styles.filters__sale}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 },}}/>}
                                label="HIT" />
                            <FormControlLabel
                                sx={{
                                    '& .MuiCheckbox-root': { display: 'none'},
                                }}
                                className={styles.filters__sale}
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />}
                                label="ДИЛЕР" />
                        </FormGroup>
                    </FilterWrapper>

                </div>
                <div className={styles.filters__block}>
                    <FilterWrapper onClick={handleClick} filterName={"Страны"} isOpen={countryFilter}>
                        <FormGroup>
                            {filters.countries.map((country: FiltersModels, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}/>}
                                        label={country.title}
                                    />
                                )
                            })}
                        </FormGroup>
                    </FilterWrapper>
                </div>
            </div>
        </form>
    )

}

export default Filter;
