import React from "react";
import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";

interface FilterWrapperProps {
    children: React.ReactNode
    onClick: () => void;
    filterName: string;
    isOpen: boolean;
}
const FilterWrapper: React.FC<FilterWrapperProps> = ({children, onClick, filterName, isOpen}) => {
    return (
        <List
            sx={{
                "& .MuiListItemText-primary": {
                    fontFamily: 'SF Pro, sans-serif',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#2F3035'
                },
                "& .MuiListItemButton-root:hover": {
                    background: 'none',
                },
                "& .MuiFormGroup-root": {
                    flexDirection: 'row',
                },
                "& .MuiListItemButton-root": {
                    padding: '8px 0',
                },
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <ListItemButton
                onClick={onClick}>
                <ListItemIcon sx={{minWidth: '28px'}}>
                    {isOpen
                        ? <img src="/assets/icons/arrow-top.svg" alt="arrow"/>
                        : <img src="/assets/icons/arrow-bottom.svg" alt="arrow"/>
                    }
                </ListItemIcon>
                <ListItemText
                    sx={{
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#2F3035'
                    }}
                    primary={filterName} />
            </ListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </List>
    )
}

export default FilterWrapper;
