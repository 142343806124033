import { createReducer } from '@reduxjs/toolkit'

import { filterActions } from "./filterActions"
import {ShopProductsList} from "../../models/filterTypes"

type FiltersState = {
    minPrice: number
    maxPrice: number
    countries: []
    models: []
    brands: []
    shopInfo: ShopProductsList
}


const initialState: FiltersState = {
    minPrice: 0,
    maxPrice: 0,
    countries: [],
    models: [],
    brands: [],
    shopInfo: { pageSize: 0, products: [] }
}


export const filterReducer = createReducer(initialState, builder => {
    builder.addCase(filterActions.getRequest, state => ({
        ...state,
        isLoadingGet: true,
    }))
    builder.addCase(filterActions.getFiltersSuccess, (state, action) => ({
        ...state,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
        brands: action.payload.brands,
        models: action.payload.models,
        countries: action.payload.countries,
        error: null,
    }))
    builder.addCase(filterActions.getFiltersFailure, (state, action) => ({
        ...state,
        isLoadingGet: false,
        error: action.payload,
    }))

    builder.addCase(filterActions.clearState, state => ({
        ...state,
        filters: initialState,
    }))
})
