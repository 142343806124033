import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import {getAuthentication} from "../../auth/selectors";
import {IInputAuthForm} from "../../models/IInputAuthForm";
import {authenticationActions} from "../../auth/actions";
import {EMAIL_REGEXP} from "../Form/emailValidate";
import {PASSWORD_REGEXP} from "../Form/passwordValidate";
import Button from "../Button/Button";
import {Link} from "react-router-dom";
import styles from "../Form/Form.module.scss";
import Input from "../Input/Input";


const RegistrationPage = () => {
    const dispatch = useDispatch()

    const { isLoadingRegistration } = useSelector(getAuthentication)

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<IInputAuthForm>()

    useEffect(() => {
        return () => reset()
    }, [])

    const onRegistration = useCallback(
        (data: IInputAuthForm) => dispatch(authenticationActions.registrationAsync(data)),
        []
    )

    return (
        <div>
            <form className={styles.form} onSubmit={handleSubmit(onRegistration)}>
                <button className={styles.form__close_btn}>
                    <img src="/assets/icons/close.svg" alt="close"/>
                </button>

                <div className={styles.form__wrapper}>
                    <img className={styles.form__logo} src="/assets/icons/logo.svg" alt="logo"/>
                    <h4 className={styles.form__title}>Регистрация</h4>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                            pattern: EMAIL_REGEXP,
                        }}
                        name='email'
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                inputClassName={styles.form__input}
                                placeholder={'Электронная почта'}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={!!errors?.email}
                                disabled={isLoadingRegistration}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                        }}
                        name='name'
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                inputClassName={styles.form__input}
                                placeholder={'Имя пользователя'}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={!!errors?.name}
                                disabled={isLoadingRegistration}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                            pattern: PASSWORD_REGEXP,
                        }}
                        name='password'
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                inputClassName={styles.form__input}
                                placeholder={'Пароль'}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                type={'password'}
                                error={!!errors?.password}
                                disabled={isLoadingRegistration}
                                // hint={
                                //     'Пароль должен содержать: минимум 6 символов, цифры, заглавные и строчные буквы и спецсимвол.'
                                // }
                            />
                        )}
                    />
                    <Button
                        title={'Зарегистрироваться'}
                        onClick={()=>{}}
                        disabled={isLoadingRegistration}
                    />
                    <Link className={styles.form__link} to={"#"}>Войти</Link>
                </div>
            </form>
        </div>
    )
}

export default RegistrationPage
