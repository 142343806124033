import React, {useEffect, useState} from "react";

import Header from "../../components/Header/Header";
import Search from "../../components/Search/Search";
import Card from "../../components/Card/Card";
import PageLayout from "../../components/PageLayout/PageLayout";
import Footer from "../../components/Footer/Footer";
import Filter from "../../components/Filter/Filter";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from "./HomePage.module.scss";
import '../../styles/slider.scss'

import axios from "axios";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import {Link} from "react-router-dom";
import PaginationMui from "@mui/material/Pagination";
import {List, PaginationItem, Stack} from "@mui/material";
import {ShopProduct} from "../../models/filterTypes";
import FilterWrapper from "../../components/FilterWrapper/FilterWrapper";
import Login from "../../components/Login/Login";

const HomePage: React.FC = () => {

    const breadcrumbs = [
        {route: '/', name: 'Главная'},
        {route: '/', name: 'Гидроциклы'},
    ]

    const [openFilters, setOpenFilters] = useState(false)
    const [items, setItems] = useState<ShopProduct[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [page, setPage] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);

    const handleClick = () => {
        setOpenFilters(!openFilters);
    };

    useEffect(() => {
        axios.get('https://frontexam.dock8.66bit.ru/api/products')
            .then((response) => {
                setItems(response.data.products);
            })
    }, [])

    useEffect(() => {
        axios.get(`https://frontexam.dock8.66bit.ru/api/products?PageNumber=${page}`)
            .then((response) => {
                setPageTotal(response.data.pageCount);
                setItems(response.data.products);
            })
    }, [page])

    return (
        <div className={styles.home}>
            <PageLayout>
                <Header/>
                <main>
                    <Breadcrumbs breadcrumbItems={breadcrumbs}/>
                    <div className={styles.home__ad}>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            <SwiperSlide><img src="/assets/img/slider.png" alt="slider"/></SwiperSlide>
                            <SwiperSlide><img src="/assets/img/slider.png" alt="slider"/></SwiperSlide>
                            <SwiperSlide><img src="/assets/img/slider.png" alt="slider"/></SwiperSlide>
                            <SwiperSlide><img src="/assets/img/slider.png" alt="slider"/></SwiperSlide>
                        </Swiper>
                        <div className={styles.ad__card}>
                            <div className={styles.ad__card_action}>
                                <span className={styles.ad__card_action_text}>акция</span>
                            </div>
                            <div className={styles.ad__card_wrapper}>
                                <div className={styles.ad__card_price}>190 000 ₽</div>
                                <div className={styles.ad__card_oldPrice}>225 000 ₽</div>
                                <div className={styles.ad__card_image}>
                                    <img src="/assets/img/promo.png" alt="card"/>
                                </div>
                                <div className={styles.ad__card_name}>Лодочный мотор
                                    Suzuki DF9.9BRS
                                </div>
                            </div>
                            <div className={styles.ad__card_promo}>
                                <p className={styles.ad__card_text}>
                                    Акция действует до <br/>
                                    <span className={styles.ad__card_date}>31.08.2020</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className={styles.home__p}>Поиск по названию товара</p>
                    <Search searchValue={searchValue} setSearchValue={setSearchValue}/>
                    <h1 className={styles.home__title}>Гидроциклы</h1>
                    <div className={styles.home__section}>
                        <div className={styles.home__filters_mob}>
                            <FilterWrapper onClick={handleClick} filterName={"Фильтры"} isOpen={openFilters}>
                                <Filter/>
                            </FilterWrapper>
                        </div>
                        <div className={styles.home__filters}>
                            <Filter />
                        </div>

                        <div className={styles.home__cards}>
                            {items
                                .filter((item) => item.title.toLowerCase().includes(searchValue.toLowerCase()))
                                .map(
                                    (item: {
                                        title: string;
                                        img: string;
                                        isInInventory:boolean;
                                        isOnRequest: boolean;
                                        price: number;
                                        modelId: string;
                                        brandId: string;
                                        countryId: string;
                                        actions: number[];
                                        id: string;
                                        isFavourite: boolean
                                    }) => {
                                return (
                                    <Card
                                        title={item.title}
                                        img={item.img}
                                        isInInventory={item.isInInventory}
                                        isOnRequest={item.isOnRequest}
                                        price={item.price}
                                        modelId={item.modelId}
                                        brandId={item.brandId}
                                        countryId={item.countryId}
                                        actions={item.actions}
                                        id={item.id}
                                        isFavourite={item.isFavourite}
                                        key={item.id}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <Stack spacing={2}>
                        {!!pageTotal && (
                            <PaginationMui
                                count={pageTotal}
                                page={page}
                                onChange={(_, num) => setPage(num)}
                                sx={{
                                    "&": {
                                        marginY: 3,
                                        marginX: "auto"
                                    },
                                    "& .MuiPaginationItem-root": {
                                        borderRadius: '0px',
                                        background: 'none',
                                        color: '#1C62CD'
                                    },
                                    "& .MuiPaginationItem-root.Mui-selected": {
                                        background: 'none',
                                        border: '2px solid #1C62CD',
                                    },
                                    "& .MuiPaginationItem-root:hover": {
                                        background: 'rgba(28, 98, 205, 0.15)',
                                    },
                                    "& .MuiPaginationItem-root.Mui-selected:hover": {
                                        background: 'rgba(28, 98, 205, 0.15)',
                                    },
                                }}
                                renderItem={(item) => (
                                    <PaginationItem
                                        component={Link}
                                        to={`?PageNumber=${item.page}`}
                                        {...item}
                                    />
                                )}
                            />
                        )}
                    </Stack>
                </main>
            </PageLayout>
            <Footer/>
        </div>

    )
}

export default HomePage;
