import React from "react";
import {ShopProduct} from "../../models/filterTypes";
import {Link} from "react-router-dom";

const Card: React.FC<ShopProduct> = ({ title,
                                         img,
                                         isInInventory,
                                         isOnRequest,
                                         price,
                                         modelId,
                                         brandId,
                                         countryId,
                                         actions,
                                         isFavourite,
                                         id, }) => {
    const formatPrice = (price: number): string => {
        return (
            price.toLocaleString("ru", {
                style: "currency",
                currency: "RUB",
                minimumFractionDigits: 0
            }))
    }

    return (
        <div>
            <div className="card">
                <div className="card__wrapper">
                    <button className="card__favourite">
                        <img src={isFavourite ? "/assets/icons/favouriteFilled.svg" : "/assets/icons/favourite.svg"}
                             alt="favourite"/>
                    </button>
                    <div className="card__image">
                        <img src={img} alt="card"/>
                    </div>
                    <div className="card__name">{title}</div>
                    <div className="card__price">
                        {isInInventory ? formatPrice(price) : (
                            <div className="card__inventory">
                                <span className="card__inventory_none">нет в наличии</span>
                                <Link className="card__inventory_message" to={"#"}>Сообщить о поступлении</Link>
                            </div>
                        )}
                    </div>
                </div>
                {isInInventory ? (
                    <button className="card__basket">
                        <img src="/assets/icons/card-basket.svg" alt="basket"/>
                    </button>
                ) : ""}
            </div>
        </div>
    )
}

export default Card;
