import { all, call } from 'redux-saga/effects'

import {getFiltersWatcher} from "../components/Filter/filterWatcher";
import {watchAuthentication} from "../auth/watchers";

export function* rootSaga() {
    yield all([
        call(getFiltersWatcher),
        call(watchAuthentication),
    ])
}
