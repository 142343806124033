import {createAction} from "@reduxjs/toolkit";
import {filtersTypes} from "./types";
export const filterActions = {
    getRequest: createAction(filtersTypes.GET_REQUEST),
    getFiltersSuccess: createAction(filtersTypes.GET_SUCCESS, (data) => ({
        payload: data,
    })),
    getFiltersFailure: createAction(filtersTypes.GET_FAILURE, (error: string) => ({
        payload: error,
    })),
    clearState: createAction(filtersTypes.CLEAR_STATE),
}