import React, {useState} from "react";
import Header from "../../components/Header/Header";
import Search from "../../components/Search/Search";
import PageLayout from "../../components/PageLayout/PageLayout";
import Footer from "../../components/Footer/Footer";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const AccountPage: React.FC = () => {
    const breadcrumbs = [
        {route: '/', name: 'Главная'},
        {route: '/account', name: 'Личный кабинет'},
    ]
    const [searchValue, setSearchValue] = useState<string>('');

    return (
        <div className="">
            <PageLayout>
                <Header/>
                <Breadcrumbs breadcrumbItems={breadcrumbs} />
                <Search searchValue={searchValue} setSearchValue={setSearchValue}/>
            </PageLayout>
            <Footer/>

        </div>
    )
}

export default AccountPage;
