import React from 'react';
import styles from './PageLayout.module.scss';

interface Props {
    children: React.ReactNode
}
const PageLayout: React.FC<Props> = (props:Props) =>{
    return(
        <div className={styles.layout}>
            {props.children}
        </div>
    )
}

export default PageLayout;
