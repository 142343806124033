import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Box, Fade, Modal} from "@mui/material";
import Login from "../Login/Login";
import Register from "../Register/Register";
const Header: React.FC = () => {
    const [openModal, setOpenModal] = useState(false);
    const [openBurger, setOpenBurger] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleBurger = () => setOpenBurger(!openBurger)

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '3px',
    };
    return (
        <header className="header">
            <div className="header__container">
                <div className="header__menu menu">
                    <button
                        className={openBurger ? "header__burger_open hidden" : "header__burger_open"}
                        onClick={handleBurger}
                    >
                        <span></span>
                    </button>

                    <ul className={openBurger ? "menu__list_mobile active" : "menu__list_mobile"}>
                        <button
                            className="header__burger_close"
                            onClick={handleBurger}
                        >
                            <img src="/assets/icons/close.svg" alt=""/>
                        </button>
                        <li className="menu__item_mob">
                            <Link to="/"><img src="/assets/icons/person.svg" alt=""/>Войти</Link>
                        </li>
                        <li className="menu__item_mob">
                            <Link to="/"><img src="/assets/icons/person.svg" alt=""/>Регистрация</Link>
                        </li>
                        <li className="menu__item_mob">
                            <Link to="/"><img src="/assets/icons/basket.svg" alt=""/>Корзина</Link>
                        </li>
                        <li className="menu__item_mob">
                            <Link to="/"><img src="/assets/icons/home.svg" alt=""/>Магазины</Link>
                        </li>
                        <li className="menu__item_mob">
                            <Link to="/"><img src="/assets/icons/sale.svg" alt=""/>Акции</Link>
                        </li>
                        <li className="menu__item_mob">
                            <Link to="/"><img src="/assets/icons/delivery.svg" alt=""/>Доставка и оплата</Link>
                        </li>
                        <li className="nav__item_mob"><Link to="/">Квадроциклы</Link></li>
                        <li className="nav__item_mob"><Link to="/">Катера</Link></li>
                        <li className="nav__item_mob"><Link to="/hydrocycles">Гидроциклы</Link></li>
                        <li className="nav__item_mob"><Link to="/">Лодки</Link></li>
                        <li className="nav__item_mob"><Link to="/">Вездеходы</Link></li>
                        <li className="nav__item_mob"><Link to="/">Снегоходы</Link></li>
                        <li className="nav__item_mob"><Link to="/">Двигатели</Link></li>
                        <li className="nav__item_mob"><Link to="/">Запчасти</Link></li>
                    </ul>

                    <ul className="menu__section_left">
                        <li className="menu__item"><Link to="/">Магазины</Link></li>
                        <li className="menu__item"><Link to="/">Акции</Link></li>
                        <li className="menu__item"><Link to="/">Доставка и оплата</Link></li>
                    </ul>
                    <Link className="header__logo_mob" to="/">
                        <img src="/assets/icons/logo-mobile.svg" alt="logo"/>
                    </Link>
                    <Link className="header__logo" to="/">
                        <img src="/assets/icons/logo.svg" alt="logo"/>
                    </Link>
                    <div className="menu__section_right">
                        <address className="menu__geo">
                            <img src="/assets/icons/geo.svg" alt="geolocation"/>Москва,  ул. Науки  25
                        </address>
                        <div className="menu__btns">
                            <Link className="menu__login" to="/" onClick={handleOpenModal} >
                                <img src="/assets/icons/person.svg" alt="person"/>
                                Войти
                            </Link>
                            <Modal
                                aria-labelledby="spring-modal-title"
                                aria-describedby="spring-modal-description"
                                open={openModal}
                                onClose={handleCloseModal}
                                closeAfterTransition
                            >
                                <Fade in={openModal}>
                                    <Box sx={style}>
                                        <Register />
                                    </Box>
                                </Fade>
                            </Modal>
                            <Link to="/">
                                <img src="/assets/icons/basket.svg" alt="basket"/>
                            </Link>

                        </div>
                    </div>
                </div>
                <nav className="header__nav nav">
                    <ul className="nav__list">
                        <li className="nav__item"><Link to="/">Квадроциклы</Link></li>
                        <li className="nav__item"><Link to="/">Катера</Link></li>
                        <li className="nav__item"><Link to="/">Гидроциклы</Link></li>
                        <li className="nav__item"><Link to="/">Лодки</Link></li>
                        <li className="nav__item"><Link to="/">Вездеходы</Link></li>
                        <li className="nav__item"><Link to="/">Снегоходы</Link></li>
                        <li className="nav__item"><Link to="/">Двигатели</Link></li>
                        <li className="nav__item"><Link to="/">Запчасти</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header;
