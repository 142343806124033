import React, {Dispatch, SetStateAction} from "react";

interface SearchProps {
    searchValue: string
    setSearchValue: Dispatch<SetStateAction<string>>
}
const Search: React.FC<SearchProps> = ({ searchValue, setSearchValue}) => {
    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredName = event.target.value;
        setSearchValue(enteredName);
    };

    return (
        <div className="search">
            <div className="search__wrapper">
                <input
                    className="search__input"
                    type="text"
                    placeholder="Введите марку"
                    value={searchValue}
                    onChange={inputHandler}
                />
                <button className="search__button">
                    искать
                </button>
            </div>
        </div>
    )
}

export default Search;