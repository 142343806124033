import React from "react";
import styles from "./Footer.module.scss";
import Button from "../Button/Button";

const Footer: React.FC = () => {
    return(
        <footer className={styles.footer}>
            <div className={styles.footer__wrapper}>
                <div className={styles.mailing}>
                    <h4 className={styles.mailing__title}>Подпишитесь на нашу рассылку
                        и узнавайте об акциях быстрее</h4>
                    <div className={styles.mailing__form}>
                        <input
                            className={styles.mailing__input}
                            type="email"
                            placeholder="Введите ваш e-mail:"
                        />
                        <Button title={"Отправить"} onClick={() => {}} disabled={false} />
                    </div>
                </div>
                <div className={styles.col}>
                    <h4 className={styles.col__title}>Информация</h4>
                    <ul className={styles.col__list}>
                        <li className={styles.col__item}><a href="#">О компании</a></li>
                        <li className={styles.col__item}><a href="#">Контакты</a></li>
                        <li className={styles.col__item}><a href="#">Акции</a></li>
                        <li className={styles.col__item}><a href="#">Магазины</a></li>
                    </ul>
                </div>
                <div className={styles.col}>
                    <h4 className={styles.col__title}>Интернет-магазин</h4>
                    <ul className={styles.col__list}>
                        <li className={styles.col__item}><a href="#">Доставка и самовывоз</a></li>
                        <li className={styles.col__item}><a href="#">Оплата</a></li>
                        <li className={styles.col__item}><a href="#">Возврат-обмен</a></li>
                        <li className={styles.col__item}><a href="#">Новости</a></li>
                    </ul>
                </div>
                <address className={styles.social}>
                    <ul className={styles.social__list}>
                        <li className={styles.social__item}><a href="#"><img src="/assets/icons/social/inst.svg" alt="inst"/></a></li>
                        <li className={styles.social__item}><a href="#"><img src="/assets/icons/social/vk.svg" alt="vk"/></a></li>
                        <li className={styles.social__item}><a href="#"><img src="/assets/icons/social/facebok.svg" alt="facebok"/></a></li>
                        <li className={styles.social__item}><a href="#"><img src="/assets/icons/social/youtube.svg" alt="youtube"/></a></li>
                    </ul>
                </address>
            </div>
        </footer>
    )
}

export default Footer;