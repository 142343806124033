import React from "react";
import BreadcrumbItem, {BreadcrumbItemProps} from "./BreadcrumbItem/BreadcrumbItem";
import styles from "./BreadcrumbItem/BreadcrumbItem.module.scss";


interface BreadcrumbProps {
    breadcrumbItems: BreadcrumbItemProps[];
}
const Breadcrumbs: React.FC<BreadcrumbProps> = ({breadcrumbItems}) => {

    return (
        <nav className={styles.breadcrumb__nav}>
            <ol>
                {breadcrumbItems.map((item, index, array) => {
                    return (
                        <BreadcrumbItem {...item} index={index + 1} isLast={array.length - 1 === index} key={index} />
                    )
                })}
            </ol>
        </nav>
    )
}
export default Breadcrumbs;
