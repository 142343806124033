import {Link} from "react-router-dom";
import styles from "./BreadcrumbItem.module.scss"

export interface BreadcrumbItemProps {
    route: string;
    name: string;
    index?: number;
    isLast?: boolean
}
const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ route, name, index, isLast }) => {
    return (
        <Link className={styles.breadcrumb} to={route} key={index}>
            {name}
            {isLast ? "" : <span className={styles.breadcrumb__divider}>{'>'}</span>}
        </Link>
    )
}

export default BreadcrumbItem;
